import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';
import '../styles/404.scss';

const NotFoundPage = ({ data }) => (
  <Layout navbarData={data.navbarData}>
    <Helmet>
      <title>Mitá - Page Not Found</title>
    </Helmet>
    <div className="pageNotFound container">
      <h1>Aquí no hay nada!</h1>
      <hr className="separator" />
      <p>Parece que te has equivocado de página</p>
    </div>
  </Layout>
);

export default NotFoundPage;

export const pageQuery = graphql`
  query ErrorPageQuery {
    ...LayoutFragment
  }
`;
